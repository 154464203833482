const Apis = {
  API_KEY: '1242a71f8a5851b539f2a5879ddbf96defd02e7e1c06e9aeb64a21c3fdc8f3b5',
  GET_AVAILABLE_CURRENCIES:
    'https://api.changenow.io/v2/exchange/currencies?active=&flow=standard&buy=&sell=',
  GET_AVAILABLE_FIAT: 'https://api.changenow.io/v2/fiat-currencies/fiat',
  GET_ESTIMATED: 'https://api.changenow.io/v2/exchange/estimated-amount',
  CREATE_EXCHANGE: 'https://api.changenow.io/v2/exchange',
  GET_MINIMAL_EXCHANGE_AMOUNT: 'https://api.changenow.io/v2/exchange/min-amount',
  GET_VALID_ADDRESS: 'https://api.changenow.io/v2/validate/address',
};

export default Apis;
