import NewHeader from '@groot/components/NewHeader';
import React from 'react';

export default function ECOSYSTEM() {
  return (
    <article id="post-96" className="post-96 page type-page status-publish hentry">
      <NewHeader />
      <div className="entry-content">
        <div id="et-boc" className="et-boc">
          <div className="et-l et-l--post">
            <div className="et_builder_inner_content et_pb_gutters3">
              <div className="et_pb_section et_pb_section_0 et_pb_fullwidth_section et_section_regular">
                <div className="et_pb_module et_pb_fullwidth_image et_pb_fullwidth_image_0">
                  <img
                    loading="lazy"
                    src="./assets/image/bg/growthdefi.svg"
                    alt=""
                    title="growthdefi"
                    height="auto"
                    width="auto"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
}
