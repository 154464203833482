/** @jsxImportSource theme-ui */
import React from 'react';
import { Flex, Text } from 'theme-ui';
import AOS from 'aos';
import Container from '@groot/components/Container';
import Counter from '@groot/components/Counter';
import ExternalLink from '@groot/components/ExternalLink';
import { theme } from '@groot/shared/util';

import xgroLogo from './images/growthdefi_logo.svg';
import wheatLogo from './images/WheatLogo.png';
import certikLogo from './images/CertikLogo.png';
import defiYieldLogo from './images/DeFiYieldLogo.png';
import iliDiligenceLogo from './images/iliDiligenceLogo.png';

import heroImage from './images/HeroImage.png';
import NewHeader from '@groot/components/NewHeader';

import {
  Page,
  Hero,
  HeroContent,
  HeroObject,
  CardsList,
  Card,
  CardTitle,
  CardFooter,
  WheatLogo,
  MorLogo,
  CertikLogo,
  DefiYeldLogo,
  IliDiligenceLogo,
} from './audits.styled';

export default function Audits() {
  React.useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  return (
    <Page>
      <NewHeader />
      <Hero>
        <Container>
          <Flex
            style={{ alignItems: 'center', justifyContent: 'space-between', flexFlow: 'row wrap' }}
          >
            <HeroContent
              data-aos="fade-up"
              data-aos-offset="-340"
              data-aos-delay="0"
              data-aos-duration="600"
              data-aos-easing="ease-in-out"
              data-aos-mirror="false"
              data-aos-once="true"
              data-aos-anchor-placement="top-center"
            >
              <h1 style={{ ...theme.styles.h1, mb: 20 }}>Audits</h1>
              <p>
                Growth DeFi’s focus on security is at the forefront of all of its tokens and
                protocols. All Growth DeFi tokens and protocols have been successfully audited.
              </p>
            </HeroContent>
            <HeroObject
              data-aos="fade-up"
              data-aos-offset="-340"
              data-aos-delay="100"
              data-aos-duration="600"
              data-aos-easing="ease-in-out"
              data-aos-mirror="false"
              data-aos-once="true"
              data-aos-anchor-placement="top-center"
            >
              <img src={heroImage} />
            </HeroObject>
          </Flex>
        </Container>
      </Hero>
      <Container>
        <CardsList>
          <Card
            data-aos="fade-up"
            data-aos-offset="-340"
            data-aos-delay="200"
            data-aos-duration="600"
            data-aos-easing="ease-in-out"
            data-aos-mirror="false"
            data-aos-once="true"
            data-aos-anchor-placement="top-center"
          >
            <MorLogo src={xgroLogo} />
            <CardTitle>GRO</CardTitle>
            <CertikLogo src={certikLogo} />
            <CardFooter>
              <ExternalLink
                href="https://www.certik.com/projects/growthdefi"
                style={{ fontWeight: 600, color: '#16C6E8' }}
              >
                Read More
              </ExternalLink>
              <Text>11/18/21</Text>
            </CardFooter>
          </Card>
          <Card
            data-aos="fade-up"
            data-aos-offset="-340"
            data-aos-delay="300"
            data-aos-duration="600"
            data-aos-easing="ease-in-out"
            data-aos-mirror="false"
            data-aos-once="true"
            data-aos-anchor-placement="top-center"
          >
            <MorLogo src={xgroLogo} />
            <CardTitle>GRO</CardTitle>
            <DefiYeldLogo src={defiYieldLogo} />
            <CardFooter>
              <ExternalLink
                href="https://defiyield.app/audit-database/defiyield/growth_defi"
                style={{ fontWeight: 600, color: '#16C6E8' }}
              >
                Read More
              </ExternalLink>
              <Text>9/14/21-9/27/21</Text>
            </CardFooter>
          </Card>
          <Card
            data-aos="fade-up"
            data-aos-offset="-340"
            data-aos-delay="500"
            data-aos-duration="600"
            data-aos-easing="ease-in-out"
            data-aos-mirror="false"
            data-aos-once="true"
            data-aos-anchor-placement="top-center"
          >
            <MorLogo src={xgroLogo} />
            <CardTitle>GRO</CardTitle>
            <IliDiligenceLogo src={iliDiligenceLogo} />
            <CardFooter>
              <ExternalLink
                href="https://consensys.net/diligence/audits/2020/12/growth-defi-v1/"
                style={{ fontWeight: 600, color: '#16C6E8' }}
              >
                Read More
              </ExternalLink>
              <Text>6/14/21-7/2/21</Text>
            </CardFooter>
          </Card>
        </CardsList>
      </Container>
    </Page>
  );
}
