/** @jsxImportSource theme-ui */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import AOS from 'aos';

import { jsx, Button, Flex, Text } from 'theme-ui';
import Container from '@groot/components/Container';
import Counter from '@groot/components/Counter';
import { theme } from '@groot/shared/util';
import HeroVideo from './components/HeroVideo';
import FeatureCard from './components/FeatureCard';
import { ReactComponent as MorLogo } from './images/MorLogo.svg';
import { ReactComponent as WheatLogo } from './images/vegro.svg';
import { ReactComponent as GroLogo } from './images/GroLogo.svg';
import { ReactComponent as SecureImage } from './images/SecureImage.svg';
import { ReactComponent as LoansImage } from './images/LoansImage.svg';
import { ReactComponent as SustainableImage } from './images/gro-section-image.svg';
import { ReactComponent as BlockChainIcon } from './images/BlockChainIcon.svg';
import { ReactComponent as MultiAssetIcon } from './images/MultiAssetIcon.svg';
import { ReactComponent as SecureContractsIcon } from './images/SecureContractsIcon.svg';
import { ReactComponent as ExperienceIcon } from './images/ExperienceIcon.svg';
import { ReactComponent as RewardsIcon } from './images/RewardsIcon.svg';
import { ReactComponent as GroStatisticsIcon } from './images/GroStatisticsIcon.svg';

import groSectionLogo from './images/gro-section-image.png';
import diligenceLogo from './images/diligenceImage.png';
import Doc from 'assets/doc/Litepaper.pdf';

import wheatStatisticsIcon from './images/WheatStatisticsIcon.png';
import { AssetDocumentationSection, AssetDocumentationContent } from '../Gro/gro.styles';
import {
  BgImage,
  Hero,
  HeroContent,
  HeroObject,
  HeroButtons,
  HeroButton,
  FeatureSection,
  SecureSection,
  SecureContent,
  SecureObject,
  LoansSection,
  LoansContent,
  LoansObject,
  LoansButtons,
  LoanButton,
  InvestFeatureCard,
  WhyToInvestSection,
  BorrowSection,
  StatsSection,
  StatsCard,
  StatsCardHeader,
  StatsCardBody,
} from './home.styled';
import NewHeader from '@groot/components/NewHeader';

const StyledImg = styled.img`
  height: 150px;
  width: auto;
`;

export default function Home() {
  const [groBurnt, setGroBurnt] = useState('0');
  const [groSupply, setGroSupply] = useState('0');
  const [groMarketCap, setGroMarketCap] = useState('0');
  const [groTVL, setGroTVL] = useState('0');
  const [groPrice, setGroPrice] = useState('0');

  useEffect(() => {
    async function fetchData() {
      const groPriceData = await axios.get('https://api.coingecko.com/api/v3/coins/growth-defi');
      const groPrice = groPriceData.data.market_data.current_price.usd;
      setGroPrice(groPrice);

      const groData = await axios.get('https://api.growthdefi.com/token/gro');
      const groBurnt = groData.data.burnt * 1.0;

      setGroBurnt(parseInt(groBurnt));

      const groSupply = groData.data.totalSupply * 1.0;
      setGroSupply(parseInt(groSupply));

      const groMarketCap = groPrice * (groData.data.totalSupply * 1.0);
      setGroMarketCap(parseInt(groMarketCap));

      const groTVLData = await axios.get('https://api.growthdefi.com/tvl/gro-yield');
      const groTVL = groTVLData.data.TVL * 1.0;
      setGroTVL(parseInt(groTVL));
    }

    AOS.init({
      duration: 2000,
    });

    fetchData();
  }, []);

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  const heroText =
    'Growth DeFi provides an advanced suite of products to blockchain protocols and investors, helping to increase their capital efficiency.';

  return (
    <>
      <NewHeader />
      <Hero>
        <Container>
          <Flex style={{ justifyContent: 'space-between', flexFlow: 'row wrap' }}>
            <HeroContent
              data-aos="fade-up"
              data-aos-offset="-340"
              data-aos-delay="0"
              data-aos-duration="600"
              data-aos-easing="ease-in-out"
              data-aos-mirror="false"
              data-aos-once="true"
              data-aos-anchor-placement="top-center"
            >
              <h1 style={{ ...theme.styles.h1, mb: 20 }}>
                Where Capital Efficiency <br /> Meets Security
              </h1>
              <p>{heroText}</p>
              <HeroButtons>
                <HeroButton
                  href="https://app.growthdefi.com"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <Button
                    style={{
                      height: 50,
                      pr: 30,
                      pl: 30,
                      background: '#06EBC8',
                      fontSize: 16,
                    }}
                  >
                    Enter App
                  </Button>
                </HeroButton>
              </HeroButtons>
            </HeroContent>
          </Flex>
        </Container>
      </Hero>
      <Container>
        <LoansSection style={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <LoansContent
            data-aos="fade-up"
            data-aos-offset="-340"
            data-aos-delay="0"
            data-aos-duration="600"
            data-aos-easing="ease-in-out"
            data-aos-mirror="false"
            data-aos-once="true"
            data-aos-anchor-placement="top-center"
          >
            <h2 style={{ ...theme.styles.h2, mb: 20 }}>GROWTH ECOSYSTEM TOKENS</h2>
            {/* <p style={{ mb: 30 }}>
              All products built in the Growth Defi ecosystem have revenue directed towards xGRO
              holders. xGRO is not only defiationary, but is used for yield farming on xPERPS.
            </p> */}
            <p>
              {/* <b>xGRO Token BSC:</b> 0x4adae3ad22c4e8fb56d4ae5c7eb3abc0dd2d3379 <br /> */}
              <b>7WMM Token PLS:</b> 0x6f7903dbc2bf99a46104d8cc0d9bb54639570e4a <br />
              <b>7WMM/PLS Dextools: </b>
              <br />{' '}
              https://www.dextools.io/app/en/pulse/pair-explorer/0xaad6bb94b3c7381909b44fcdb047af9ee651a17d?t=1728311575190
              <br />
              <b>GRO Token PLS:</b> 0x09e64c2b61a5f1690ee6fbed9baf5d6990f8dfd0 <br />
              <b>GrowthBTC PLS:</b> 0x00febf86e8f0673f0feadac14b5ea1a05e744cb7 <br />
              <b>4WMM PLS:</b> 0x8bf45680485b2ac15e452a9599e87b94c5a07792 <br />
              {/* <b>xGRO BSC: </b>
              <br />{' '}
              DexTool:https://www.dextools.io/app/en/bnb/pair-explorer/0xdc612572219e50f5759a441bc9e6f14c92116757?t=1711637096602
              <br /> */}
              <b>GRO PLS: </b>
              <br />{' '}
              https://www.dextools.io/app/en/pulse/pair-explorer/0x352c69bb115abbcee27d368eb661bf1cda0a1e07?t=1713749552141
              <br />
              <b>4WMM PLS: </b>
              <br />{' '}
              https://www.dextools.io/app/en/pulse/pair-explorer/0x6d1577789ce295faeae9cbea7f9e62f8f45c3236?t=1712275533601
              <br />
              <b>GBTC PLS: </b>
              <br />{' '}
              https://www.dextools.io/app/en/pulse/pair-explorer/0x96d2bdbd073af7c5c40e12123156c97fbaaf2609?t=1712275202984
              <br />
              <b>Email:</b> admin@growthdefi.com <br />
              4WMM only 869 total supply - reflects pDAI, pWBTC, pGRO, PLS 6% buy 9% sell tax but
              100% of taxes go towards holder reflections! GROWTH ! <br />
            </p>
          </LoansContent>
          <LoansObject
            data-aos="fade-up"
            data-aos-offset="-340"
            data-aos-delay="100"
            data-aos-duration="600"
            data-aos-easing="ease-in-out"
            data-aos-mirror="false"
            data-aos-once="true"
            data-aos-anchor-placement="top-center"
          >
            <img src={groSectionLogo} alt="" style={{ width: '100%' }} />
          </LoansObject>
        </LoansSection>
      </Container>

      <Container>
        <SecureSection style={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <SecureContent
            data-aos="fade-up"
            data-aos-offset="-340"
            data-aos-delay="0"
            data-aos-duration="600"
            data-aos-easing="ease-in-out"
            data-aos-mirror="false"
            data-aos-once="true"
            data-aos-anchor-placement="top-center"
          >
            <h2 style={{ ...theme.styles.h2, mb: 20 }}>Security</h2>
            <p style={{ mb: 30 }}>
              GrowthDefi is counted among the ranks of some of DeFi’s most secure and trusted
              protocols. GrowthDefi's collateral structure has been audited by the team behind
              MetaMask—top-tier firm ConsenSys Diligence—who also audited major projects such as
              AAVE, Uniswap, 1inch, and more.
            </p>
            <a href="https://growthdefi.com/audits" target="_blank" rel="noreferrer noopener">
              <Button
                style={{
                  height: 50,
                  pr: 30,
                  pl: 30,
                  fontSize: 16,
                  background: 'linear-gradient(180deg, #16C5E7 0%, #11A9C7 100%)',
                }}
              >
                View Audits
              </Button>
            </a>
          </SecureContent>

          <img src={diligenceLogo} alt="" style={{ mt: 10, width: '100%' }} />
        </SecureSection>

        <WhyToInvestSection style={{ mb: 50, mt: 150 }}>
          <h2
            style={{ ...theme.styles.h2, mb: 20 }}
            data-aos="fade-up"
            data-aos-offset="-340"
            data-aos-delay="0"
            data-aos-duration="600"
            data-aos-easing="ease-in-out"
            data-aos-mirror="false"
            data-aos-once="true"
            data-aos-anchor-placement="top-center"
          >
            Why Invest with Growth DeFi?
          </h2>
          <p
            data-aos="fade-up"
            data-aos-offset="-340"
            data-aos-delay="100"
            data-aos-duration="600"
            data-aos-easing="ease-in-out"
            data-aos-mirror="false"
            data-aos-once="true"
            data-aos-anchor-placement="top-center"
          >
            We have created secure, sustainable DeFi platforms and products that maximize user
            yields. This includes single-asset staking, incentivized liquidity provision, and an
            innovative lending/borrowing platform that allows users to leverage yield-earning
            tokens.
          </p>
        </WhyToInvestSection>

        <FeatureSection>
          <InvestFeatureCard
            title="Experience"
            icon={<ExperienceIcon />}
            data-aos="fade-up"
            data-aos-offset="-340"
            data-aos-delay="500"
            data-aos-duration="600"
            data-aos-easing="ease-in-out"
            data-aos-mirror="false"
            data-aos-once="true"
            data-aos-anchor-placement="top-center"
          >
            <p>
              We have developed a wide range of DeFi products for a variety of blockchains,
              including IOST, Ethereum, Binance Smart Chain, and Avalanche.
            </p>
          </InvestFeatureCard>
          <InvestFeatureCard
            title="Blockchain Agnostic"
            icon={<BlockChainIcon />}
            data-aos="fade-up"
            data-aos-offset="-340"
            data-aos-delay="200"
            data-aos-duration="600"
            data-aos-easing="ease-in-out"
            data-aos-mirror="false"
            data-aos-once="true"
            data-aos-anchor-placement="top-center"
          >
            <p>
              We bring new products and strategies to our ecosystem as the DeFi world changes. Our
              platform is flexible and adaptable, which means we can follow optimal
              opportunities—regardless of the platform or chain.
            </p>
          </InvestFeatureCard>

          <InvestFeatureCard
            title="Secure Contracts"
            icon={<SecureContractsIcon />}
            data-aos="fade-up"
            data-aos-offset="-340"
            data-aos-delay="400"
            data-aos-duration="600"
            data-aos-easing="ease-in-out"
            data-aos-mirror="false"
            data-aos-once="true"
            data-aos-anchor-placement="top-center"
          >
            <p>
              Security is our top priority. Our V1 strategy contracts have been audited by top-tier
              firm ConsenSys Diligence. We also use tried and field-tested contracts that have
              proven over time to be secure. These contracts are publicly available for review.
            </p>
          </InvestFeatureCard>
        </FeatureSection>
      </Container>
      <AssetDocumentationSection as="section">
        <Container>
          <AssetDocumentationContent>
            <h3
              style={{ ...theme.styles.h3, mb: 40 }}
              data-aos="fade-in"
              data-aos-offset="-340"
              data-aos-delay="0"
              data-aos-duration="600"
              data-aos-easing="ease-in-out"
              data-aos-mirror="false"
              data-aos-once="true"
              data-aos-anchor-placement="top-center"
            >
              Explore the Documentation to Learn How GRO Works Within the Growth DeFi Ecosystem
            </h3>
            <a href={Doc} target="_blank" rel="noreferrer">
              <Button
                variant="primary"
                style={{ height: 50, pr: 30, pl: 30, fontSize: 16 }}
                data-aos="fade-in"
                data-aos-offset="-340"
                data-aos-delay="0"
                data-aos-duration="600"
                data-aos-easing="ease-in-out"
                data-aos-mirror="false"
                data-aos-once="true"
                data-aos-anchor-placement="top-center"
              >
                Litepaper
              </Button>
            </a>
          </AssetDocumentationContent>
        </Container>
      </AssetDocumentationSection>

      <BgImage
        data-aos="fade-in"
        data-aos-offset="-340"
        data-aos-delay="0"
        data-aos-duration="600"
        data-aos-easing="ease-in-out"
        data-aos-mirror="false"
        data-aos-once="true"
        data-aos-anchor-placement="top-center"
      />
    </>
  );
}
