import React from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import './style.css';
import BaseLaunchImage from './BaseLaunchImage.png';

const minuteSeconds = 60;
const hourSeconds = 3600;
const daySeconds = 86400;

const timerProps = {
  isPlaying: true,
  size: 70,
  strokeWidth: 0,
  trailColor: 'transparent',
  isSmoothColorTransition: true,
};

const renderTime = (dimension, time) => {
  return (
    <div className="time-wrapper">
      <div className="vdc-launch-time">{time}</div>
      <div className="vdc-launch-dimension">{dimension}</div>
    </div>
  );
};

const getTimeSeconds = (time) => (minuteSeconds - time) | 0;
const getTimeMinutes = (time) => ((time % hourSeconds) / minuteSeconds) | 0;
const getTimeHours = (time) => (time / hourSeconds) | 0;

export default function CircleCountdown(props) {
  const startTime = Date.now() / 1000; // use UNIX timestamp in seconds
  const endTime = 1733756400;

  const remainingTime = endTime - startTime;
  const days = Math.ceil(remainingTime / daySeconds);
  const daysDuration = days * daySeconds;

  return (
    <div style={{ width: '100%', marginBottom: 25 }}>
      <img src={BaseLaunchImage} alt="Base Launch Image" style={{ width: '60%' }} />
      <div className="vdc-launch-countdown">
        <div className="vdc-launch-circulartimer">
          <CountdownCircleTimer
            {...timerProps}
            colors="#03BAE8"
            duration={daysDuration}
            initialRemainingTime={remainingTime}
          >
            {({ elapsedTime, color }) => (
              <span className="text-white">
                {renderTime('HR', getTimeHours(daysDuration - elapsedTime))}
              </span>
            )}
          </CountdownCircleTimer>
        </div>

        <div className="vdc-launch-circulartimer">
          <CountdownCircleTimer
            {...timerProps}
            colors="#03BAE8"
            duration={hourSeconds}
            initialRemainingTime={remainingTime % hourSeconds}
            onComplete={(totalElapsedTime) => ({
              shouldRepeat: remainingTime - totalElapsedTime > minuteSeconds,
            })}
          >
            {({ elapsedTime, color }) => (
              <span className="text-white">
                {renderTime('Min', getTimeMinutes(hourSeconds - elapsedTime))}
              </span>
            )}
          </CountdownCircleTimer>
        </div>

        <div className="vdc-launch-circulartimer">
          <CountdownCircleTimer
            {...timerProps}
            colors="#03BAE8"
            duration={minuteSeconds}
            initialRemainingTime={remainingTime % minuteSeconds}
            onComplete={(totalElapsedTime) => ({
              shouldRepeat: remainingTime - totalElapsedTime > 0,
            })}
          >
            {({ elapsedTime, color }) => (
              <span className="text-white">{renderTime('Sec', getTimeSeconds(elapsedTime))}</span>
            )}
          </CountdownCircleTimer>
        </div>
      </div>
    </div>
  );
}
