import React from 'react';
import Container from '@groot/components/Container';
import { Button, Flex } from 'theme-ui';
import { theme } from '@groot/shared/util';
import groCoinImage from './images/groCoinImage.png';

import {
  Hero,
  HeroContent,
  HeroObject,
  AssetDocumentationSection,
  AssetDocumentationContent,
} from './gro.styles';
import NewHeader from '@groot/components/NewHeader';

const GroETH = () => {
  return (
    <>
      <NewHeader />
      <Hero>
        <Container>
          <Flex
            style={{ alignItems: 'center', justifyContent: 'space-between', flexFlow: 'row wrap' }}
          >
            <HeroContent>
              <h1 style={{ ...theme.styles.h1, mb: 20 }}>GRO-AI Token on Ethereum</h1>
              <br />
              <p style={{ textAlign: 'left' }}>
                GRO-AI is the governance token of the GROWTH DeFi ecosystem and also a deflationary
                token with mechanisms to grow in the long run. Under 200K circulating and decreasing
                fast.. GRO-AI will be required to mint BONZ(AI) which will be launching first week
                of December!!
              </p>
              <br />
              <p style={{ textAlign: 'left' }}>
                <b>GRO-AI Token:</b> 0x78e2535Ae44C62442A5E67C0aD59a5e3B462eFF7 <br />
                <br />
                <b>DexTools:</b>{' '}
                <a
                  href="https://www.dextools.io/app/en/ether/pair-explorer/0x5e1ddce17f355994b9af9ab7945c2503f676b807"
                  style={{ color: '#06ebc8' }}
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.dextools.io/app/en/ether/pair-explorer/0x5e1ddce17f355994b9af9ab7945c2503f676b807
                </a>{' '}
                <br />
                <br />
                <b>Email:</b> Admin@growthdefi.com
              </p>
            </HeroContent>
            <HeroObject>
              <img src={groCoinImage} alt="" style={{ width: '80%' }} />
            </HeroObject>
          </Flex>
        </Container>
      </Hero>

      <AssetDocumentationSection as="section">
        <Container>
          <AssetDocumentationContent>
            <h3 style={{ ...theme.styles.h3, mb: 40 }}>
              Explore the Documentation to Learn How GRO Works Within the Growth DeFi Ecosystem
            </h3>
            <a href="/doc/Litepaper.pdf" target="_blank" rel="noreferrer">
              <Button variant="primary" style={{ height: 50, pr: 30, pl: 30, fontSize: 16 }}>
                Asset Documentation
              </Button>
            </a>
          </AssetDocumentationContent>
        </Container>
      </AssetDocumentationSection>
    </>
  );
};

export default GroETH;
