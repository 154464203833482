import React from 'react';
import styled from 'styled-components';
import { Flex } from 'theme-ui';
import { theme } from '@groot/shared/util';

import heroPattern from './images/HeroPattern.png';
import assetsDocumentationPattern from './images/AssetsDocumentationPattern.png';

export const Hero = styled.section`
  padding: 60px 0 80px;
  background-image: url(${heroPattern});
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;

  ${theme.mediaQuery.max.sm} {
    text-align: center;
  }

  ${theme.mediaQuery.min.sm} {
    height: 608px;
    padding: 62px 0;
    text-align: center;
  }
`;

export const HeroContent = styled.article`
  width: 100%;
  margin: auto;

  ${theme.mediaQuery.max.sm} {
    text-align: center;
  }

  ${theme.mediaQuery.min.sm} {
    width: 50%;
    max-width: 570px;
    padding-right: 15px;
    text-align: center;
  }
`;

export const HeroObject = styled.article`
  width: 100%;

  ${theme.mediaQuery.min.sm} {
    width: 50%;
    max-width: 540px;
    padding-left: 15px;

    p {
      display: none;
    }
  }
`;

export const BoostMorSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    315.14deg,
    #06ebc8 -1.08%,
    #7a77e9 45.38%,
    #7a77e9 63.85%,
    #16c6e8 100.28%
  );
  height: 385px;

  h2 {
    font-size: 50px;
    font-weight: 600;
    padding: 20px;
    text-align: center;
  }
  p {
    padding: 20px;
    text-align: center;
  }
  a {
    padding: 20px;
  }
`;

export const StyledAction = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
  border-radius: 5px;
  background-color: #1c1c1c;
  text-align: center;
  padding: 30px;

  h2 {
    margin-bottom: 25px;
  }

  a {
    margin-bottom: 25px;
    width: 100%;
  }

  ${theme.mediaQuery.min.sm} {
    width: calc((100% / 3) - 30px);

    margin: 15px;
  }
`;

export const DeflationarySection = styled(Flex)`
  align-items: center;
  flex-flow: row wrap;
  justify-content: space-between;

  ${theme.mediaQuery.max.sm} {
    padding-top: 80px;
    padding-bottom: 80px;
  }
`;

export const DeflationaryContent = styled.article`
  width: 100%;

  ${theme.mediaQuery.min.sm} {
    width: 50%;
    max-width: 500px;
    padding-right: 15px;
  }
`;

export const DeflationaryObject = styled.article`
  width: 100%;

  ${theme.mediaQuery.max.sm} {
    svg {
      width: 100%;
    }
  }

  ${theme.mediaQuery.min.sm} {
    width: 50%;
    padding-left: 15px;
  }
`;

export const DeflationaryList = styled.ul``;

export const StatsSection = styled(Flex)`
  justify-content: center;
  flex-flow: row wrap;

  ${theme.mediaQuery.min.sm} {
    margin: -15px;
    padding: 110px 0;
  }
`;

export const AssetDocumentationSection = styled(Flex)`
  padding: 80px 0;
  background-image: url(${assetsDocumentationPattern});
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: 100% auto;
  text-align: center;

  ${theme.mediaQuery.min.sm} {
    padding-bottom: 160px;
  }
`;

export const AssetDocumentationContent = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 790px;
  margin: auto !important;
`;
