import React from 'react';
import Container from '@groot/components/Container';
import { Button, Flex } from 'theme-ui';
import { theme } from '@groot/shared/util';
import groCoinImage from './images/groCoinImage.png';
import Countdown from '@groot/components/CircleCountdown';

import {
  Hero,
  HeroContent,
  HeroObject,
  AssetDocumentationSection,
  AssetDocumentationContent,
} from './wmm-8.styles';
import NewHeader from '@groot/components/NewHeader';

const WMM8 = () => {
  return (
    <>
      <NewHeader />
      <Hero>
        <Container>
          <Flex
            sx={{ alignItems: 'center', justifyContent: 'space-between', flexFlow: 'row wrap' }}
          >
            <HeroContent>
              <Countdown />
              <p className="text-white">** ON Pulsechain **</p>
              <ul>
                <li style={{ textAlign: 'left' }}>* 8 different tokens</li>
                <li style={{ textAlign: 'left' }}>* Will include:</li>
                <li style={{ textAlign: 'left', marginLeft: 20 }}>
                  DAI, WBTC, INC, PLSX, HEX, AAVE, GBTC, WPLS
                </li>
                <li style={{ textAlign: 'left' }}>* 420 Total supply</li>
                <li style={{ textAlign: 'left' }}>* 6% Buy tax</li>
                <li style={{ textAlign: 'left' }}>* 9% Sell tax</li>
                <li style={{ textAlign: 'left' }}>
                  * 100% of taxes go towards holder reflections!
                </li>
                <li style={{ textAlign: 'left' }}>
                  * Token Address: 0x3f881236C3E8FA27a87F1C122F938D4c31d15394
                </li>
              </ul>
            </HeroContent>
          </Flex>
        </Container>
      </Hero>
    </>
  );
};

export default WMM8;
