import React, { useState, useEffect, useMemo, useRef } from 'react';
import { jsx, Button, Flex, Text, Box, Label } from 'theme-ui';
import CaretDownImg from './images/caretDown.png';
import ExchangeModal from '../../components/ExchangeModal';
import Apis from '@groot/consts/apis';
import axios from 'axios';
// Icons
import { IoIosSearch } from 'react-icons/io';
import { RiExchangeDollarLine } from 'react-icons/ri';
import { FaExchangeAlt } from 'react-icons/fa';
import { TfiInfoAlt } from 'react-icons/tfi';
import { LuCopy } from 'react-icons/lu';
import { GrStatusGood } from 'react-icons/gr';
import { RiEmotionUnhappyLine } from 'react-icons/ri';

// Images
import logo from 'assets/img/appLogo.svg';
import './style.css';

const ExchangeSystem = () => {
  const [originalFromAllNet, setOriginalFromAllNet] = useState([]); // Backup original list
  // transaction status
  const [finished, setFinished] = useState(false);
  const [failed, setFailed] = useState(false);
  const [rate, setRate] = useState(0);
  const [qrAddressUrl, setQrAddressUrl] = useState('');
  const [qrWithAmountUrl, setQrWithAmountUrl] = useState('');

  // const [qrAddressValue, setQrAddressValue] = useState(
  //   `Bitcoin:${'38ZtzVDgfFscVpwHFFjGFaUwmp6JpzDPdx'}`
  // );
  // const [qrWithAmountValue, setQrWithAmountValue] = useState('');

  const [qrType, setQrType] = useState(true);

  const contentRef = useRef(null); // Reference to the content for calculating height

  const [exchangeType, setExchangeType] = useState('crypto');

  // exchange Steps
  const [statusFromExchangeID, setStatusFromExchangeID] = useState('waiting');
  const [exchangeID, setExchangeID] = useState('');
  // Modals
  const [fromModalOpen, setFromModalOpen] = useState(false);
  const [toModalOpen, setToModalOpen] = useState(false);

  const [isExchangeModalOpen, setIsExchangeModalOpen] = useState(false);
  const [createExchangeModalOpen, setCreateExchangeModalOpen] = useState(false);

  const [toWalletAddress, setToWalletAddress] = useState('');

  const openFromModalHnadler = (type) => {
    setFromModalOpen(true);
  };
  const openToModalHnadler = (type) => {
    setToModalOpen(true);
  };
  const closeFromModal = () => setFromModalOpen(false);
  const closeToModal = () => setToModalOpen(false);
  const closeExchangeModal = () => {
    setIsExchangeModalOpen(false);
    setInputError('');
  };
  const closeCreateExchangeModal = () => {
    setCreateExchangeModalOpen(false);
    setInputError('');
  };

  const [fromAllNet, setFromAllNet] = useState([]);
  const [toAllNet, setToAllNet] = useState([]);

  // the index of selected curreny - FROM Currency
  const [selectedFromCurrency, setSelectedFromCurrency] = useState(0);
  // the index of selected curreny - TO Currency
  const [selectedToCurrency, setSelectedToCurrency] = useState(1);

  const [fromCurrency, setFromCurrency] = useState('btc');
  const [fromNetworkName, setFromNetworkName] = useState('Bitcoin');
  const [toNetworkName, setToNetworkName] = useState('Ethereum');

  const [fromNetwork, setFromNetwork] = useState('btc');
  const [fromAmount, setFromAmount] = useState();

  const [toCurrency, setToCurrency] = useState('eth');
  const [toNetwork, setToNetwork] = useState('eth');
  const [toAmount, setToAmount] = useState();

  const [exchangeIcon, setExchangeIcon] = useState(false);

  const [recipientAddress, setRecipientAddress] = useState('');
  // Advanced Toggle
  const [refundAddress, setRefundAddress] = useState('');
  const [promoCode, setPromoCode] = useState('');
  // Available of pairs
  const [availablePairs, setAvailablePairs] = useState(true);
  // Valid Address
  const [validAddress, setValidAddress] = useState();
  const [validRefundAddress, setValidRefundAddress] = useState(true);
  const [refundError, setRefundError] = useState('');
  // Minimal Exchange Amount - State
  const [minimalExchangeAmount, setMinimalExchangeAmount] = useState(0);
  // Input Error
  const [inputError, setInputError] = useState('');
  const [receiveInputError, setReceiveInputError] = useState('');
  // Filtering for cryptocurrency
  const [filterFromCurrencyText, setFilterFromCurrencyText] = useState(''); // State to store the input text
  const [filterToCurrencyText, setFilterToCurrencyText] = useState(''); // State to store the input text

  // Handle input change
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleFromFilterChange = (event) => {
    const input = event.target.value;
    setFilterFromCurrencyText(input); // Update input text

    // Filter the available currencies based on input text
    const filtered = originalFromAllNet.filter(
      (item) => item.name.toLowerCase().includes(input.toLowerCase()) // Filter by network
    );

    setFromAllNet(filtered); // Update the filtered list
  };

  const handleToFilterChange = (event) => {
    const input = event.target.value;
    setFilterToCurrencyText(input); // Update input text
    // Filter the available currencies based on input text
    const filtered = originalFromAllNet.filter(
      (item) => item.name.toLowerCase().includes(input.toLowerCase()) // Filter by network
    );

    setToAllNet(filtered); // Update the filtered list
  };
  // Create Exchange
  const [error, setError] = useState(null); // Track any errors
  useEffect(() => {
    if (exchangeID == '') return;
    const interval = 5000; // Polling interval in milliseconds (5 seconds)
    const url = `https://api.changenow.io/v2/exchange/by-id?id=${exchangeID}`;
    const apiKey = Apis.API_KEY; // Replace with your actual API key

    const fetchStatus = async () => {
      try {
        const response = await axios.get(url, {
          headers: {
            'Content-Type': 'application/json',
            'x-changenow-api-key': apiKey,
          },
        });

        const currentStatus = response?.data?.status;
        setStatusFromExchangeID(currentStatus);

        // Stop polling if the status is no longer "Exchanging"
        if (currentStatus == 'finished') {
          setFinished(true);
          // clearInterval(polling); // Stop polling
        }
      } catch (err) {
        setError(err.message);
        setFailed(true);
        console.error('Error fetching status:', err.message);
      }
    };

    // Start polling every 5 seconds
    const polling = setInterval(fetchStatus, interval);

    // Initial fetch to get the first status immediately
    fetchStatus();

    // Cleanup interval on component unmount
    return () => clearInterval(polling);
  }, [exchangeID]);

  // onExchangeIconHandler
  const onExchangeIconHandler = () => {
    setExchangeIcon(!exchangeIcon);
    setSelectedFromCurrency(selectedToCurrency);
    setSelectedToCurrency(selectedFromCurrency);
    setFromCurrency(toCurrency);
    setToCurrency(fromCurrency);
    setFromNetwork(toNetwork);
    setToNetwork(fromNetwork);
    setFromNetworkName(toNetworkName);
    setToNetworkName(fromNetworkName);
  };

  // Get available curreny lists
  const fetchCurrencies = async () => {
    try {
      const response = await axios.get(Apis.GET_AVAILABLE_CURRENCIES, {
        headers: {
          'Content-Type': 'application/json',
        },
        params: {
          active: true,
        },
      });
      const pulseObject = response?.data.find((item) => item.network === 'pulse');
      if (pulseObject) {
        const filteredData = response?.data.filter((item) => item.network !== 'pulse');

        // Insert pulseObject into the third position
        const updatedData = [
          ...filteredData.slice(0, 2), // Items before the 3rd position
          pulseObject, // Insert pulseObject at the 3rd position
          ...filteredData.slice(2), // Items after the 3rd position
        ];

        setFromAllNet(updatedData);
        setToAllNet(updatedData);
        setOriginalFromAllNet(updatedData);
        // setOriginalToAllNet(updatedData);
      }
    } catch (error) {
      console.error('Error fetching currencies:', error);
    }
  };
  useEffect(() => {
    fetchCurrencies();
  }, []);
  // Get Minimum Amount Handler
  const fetchMinimalExchangeAmount = async () => {
    console.log(
      'fetchMinimalExchangeAmount >>>',
      fromNetwork,
      fromCurrency,
      toNetwork,
      toCurrency,
      fromAmount
    );
    try {
      const response = await axios.get(Apis.GET_MINIMAL_EXCHANGE_AMOUNT, {
        headers: {
          'Content-Type': 'application/json',
          'x-changenow-api-key': Apis.API_KEY, // Replace with your API key if required
        },
        params: {
          fromCurrency: fromCurrency,
          toCurrency: toCurrency,
          fromNetwork: fromNetwork,
          toNetwork: toNetwork,
          flow: 'fixed-rate',
        },
      });
      let miniumAmount = await response?.data?.minAmount;
      console.log('miniumAmount >>>', miniumAmount);
      setAvailablePairs(true);
      setMinimalExchangeAmount(miniumAmount);
    } catch (error) {
      console.error('Fetching Minimum error:', error);
      setInputError('Unavailable trading pairs');
      setAvailablePairs(false);
    }
  };
  useEffect(() => {
    fetchMinimalExchangeAmount();
  }, [fromNetwork, toNetwork]);
  /** Test_Start */
  // Get Estimated exchange amount
  const fetchEstimatedExchangeAmount = async () => {
    console.log('FromAmount >>>', fromAmount);
    if (minimalExchangeAmount < 0 || fromAmount === undefined) {
      return;
    } else if (isNaN(fromAmount)) {
      console.log('Nan >>>>>>');
      setToAmount(0);
      return;
    }

    try {
      // if (fromAmount < 0.000000000000000001 || isNaN(fromAmount) || fromAmount === undefined)
      //   return;
      const response = await axios.get(Apis.GET_ESTIMATED, {
        headers: {
          'Content-Type': 'application/json',
          'x-changenow-api-key': Apis.API_KEY, // Replace with your API key if required
        },
        params: {
          fromCurrency: fromCurrency,
          toCurrency: toCurrency,
          fromAmount: fromAmount,
          fromNetwork: fromNetwork,
          toNetwork: toNetwork,
          flow: 'fixed-rate',
        },
      });
      console.log('>>>>>>>>>>>>', response?.data?.toAmount);
      setRate(response?.data?.toAmount / fromAmount);
      setToAmount(response?.data?.toAmount);
      setInputError('');
    } catch (error) {
      console.log('fetchEstimatedExchangeAmount Error >>>', error.response);
      setToAmount(0);
      setRate(0);
      if (error.response && error.response.data) {
        const { message, payload } = error.response.data;
        console.log('message >>>', message, payload);
        if (message == 'amountFrom is too small') {
          setInputError(
            `Exceeded. Min: ${payload.range.minAmount.toFixed(
              5
            )}, Max: ${payload.range.maxAmount.toFixed(5)}`
          );
        } else if (message == 'Exceeded max amount') {
          setInputError(`Exceeded max amount: ${payload.range.maxAmount.toFixed(5)}`);
        }
      } else {
        setInputError('An unexpected error occurred. Please try again later.');
        return;
      }
      console.error('Error Fetching Estimated Exchange Amount:', error);
      return;
    }
  };
  useEffect(() => {
    fetchEstimatedExchangeAmount();
  }, [exchangeIcon, minimalExchangeAmount, fromNetwork, toNetwork]);
  // toAmount onChange Handler
  const handleToAmountChange = async (e) => {
    if (availablePairs === false) {
      return;
    } else if (e.target.value < 0 || isNaN(e.target.value)) {
      return;
    }
    try {
      let input = e.target.value;
      setToAmount(input);
      const response = await axios.get(Apis.GET_ESTIMATED, {
        headers: {
          'Content-Type': 'application/json',
          'x-changenow-api-key': Apis.API_KEY, // Replace with your API key if required
        },
        params: {
          fromCurrency: toCurrency,
          toCurrency: fromCurrency,
          fromAmount: input,
          fromNetwork: toNetwork,
          toNetwork: fromNetwork,
          flow: 'fixed-rate',
        },
      });
      let fromAmountValue = response?.data?.toAmount;
      setReceiveInputError('');
      console.log('fromAmountValue >>>>>', fromAmountValue);
      setFromAmount(fromAmountValue);
    } catch (error) {
      console.log('handleToAmountChange >>> ', error.response);
      let payload = error?.response?.data?.payload;
      if (error.response?.data?.error === 'not_valid_params') {
        setReceiveInputError(
          `Min: ${payload?.range.minAmount.toFixed(7)}, Max: ${payload?.range.maxAmount.toFixed(7)}`
        );
      }
      setFromAmount(0);
      return;
    }
  };
  const handleFromAmountChange = async (e) => {
    console.log('From Change Handler >>>', availablePairs);
    if (availablePairs === false) {
      return;
    }
    const input = parseFloat(e.target.value);
    setFromAmount(input);
    console.log('Type >>> ', typeof input);
    try {
      // if (fromAmount < 0.000000000000000001 || isNaN(fromAmount) || fromAmount === undefined)
      //   return;
      const response = await axios.get(Apis.GET_ESTIMATED, {
        headers: {
          'Content-Type': 'application/json',
          'x-changenow-api-key': Apis.API_KEY, // Replace with your API key if required
        },
        params: {
          fromCurrency: fromCurrency,
          toCurrency: toCurrency,
          fromAmount: input,
          fromNetwork: fromNetwork,
          toNetwork: toNetwork,
          flow: 'fixed-rate',
        },
      });
      console.log('>>>>>>>>>>>>', response?.data?.toAmount);
      setRate(response?.data?.toAmount / input);
      setToAmount(response?.data?.toAmount);
      setInputError('');
    } catch (error) {
      console.log('fetchEstimatedExchangeAmount Error >>>', error.response);
      setToAmount(0);
      setRate(0);
      if (error.response && error.response.data) {
        const { message, payload } = error.response.data;
        console.log('message >>>', message, payload);
        if (message == 'amountFrom is too small') {
          setInputError(
            `Exceeded. Min: ${payload.range.minAmount.toFixed(
              5
            )}, Max: ${payload.range.maxAmount.toFixed(5)}`
          );
        } else if (message == 'Exceeded max amount') {
          setInputError(`Exceeded max amount: ${payload.range.maxAmount.toFixed(5)}`);
        }
      } else {
        setInputError('An unexpected error occurred. Please try again later.');
        return;
      }
      console.error('Error Fetching Estimated Exchange Amount:', error);
      return;
    }
  };

  /** Test_End */

  const onExchangeTypeHandler = () => {
    setExchangeType('crypto');
  };

  const handleRefundChange = (e) => {
    setRefundAddress(e.target.value);
  };
  const handlePromoCodeChange = (e) => {
    setPromoCode(e.target.value);
  };
  useEffect(() => {
    if (isNaN(fromAmount) || fromAmount == undefined) {
      setInputError('Please fill the amount');
      setToAmount();
    } else {
      setInputError('');
    }
  }, [fromAmount, fromNetwork, toNetwork]);

  const handleExchange = () => {
    if (
      availablePairs == false ||
      fromAmount === undefined ||
      fromAmount < 0.0000000000000000000001
    ) {
      return;
    }
    setIsExchangeModalOpen(true);
  };

  const onHandleExchange = async () => {
    if (recipientAddress == '') {
      return;
    }
    try {
      const response = await axios.get(Apis.GET_VALID_ADDRESS, {
        params: {
          currency: toCurrency,
          address: recipientAddress,
        },
      });

      console.log('Validation >>>', response?.data.result);
      if (response?.data.result == true) {
        setValidAddress(true);
      } else {
        setInputError(`This address should be ${toNetwork.toUpperCase()} `);
        setValidAddress(false);
        return;
      }
    } catch (error) {
      setInputError(`This address should be ${toNetwork.toUpperCase()} `);
      console.log('Validation Error :', error);
      setValidAddress(false);
      return;
    }

    if (refundAddress !== '') {
      try {
        const res = await axios.get(Apis.GET_VALID_ADDRESS, {
          params: {
            currency: fromCurrency,
            address: refundAddress,
          },
        });
        if (res?.data.result == true) {
          setValidRefundAddress(true);
        } else {
          setValidRefundAddress(false);
          setRefundError(res?.data.message);
          return;
        }
      } catch (error) {
        setValidRefundAddress(false);
        setRefundError('Invalid Refund Address');
        return;
      }
    }
    let payInAddress = '';
    try {
      const response = await axios.post(
        Apis.CREATE_EXCHANGE,
        {
          fromCurrency: fromCurrency,
          toCurrency: toCurrency,
          fromAmount: fromAmount,
          fromNetwork: fromNetwork,
          toNetwork: toNetwork,
          address: recipientAddress,
          refundAddress: refundAddress,
          flow: 'standard',
          type: 'direct',
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'x-changenow-api-key': Apis.API_KEY, // Replace with your API key if required
          },
        }
      );
      console.log('Res>>>: ', response.data);
      payInAddress = await response?.data?.payinAddress;
      setToWalletAddress(response?.data?.payinAddress);
      setExchangeID(response?.data?.id);
    } catch (error) {
      closeCreateExchangeModal();
      closeExchangeModal();
      setFailed(true);
      console.error('Error fetching Create-Exchange data:', error);
      return;
    }

    // Initialize QRCodeValue before using it
    let QRCodeValue = `${fromNetworkName}:${payInAddress}`;
    console.log('payInAddress >>>', payInAddress, QRCodeValue);

    const apiAddressUri = `https://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(
      QRCodeValue
    )}&size=150x150`;

    // Fetch the QR code image without Amount
    await axios
      .get(apiAddressUri, { responseType: 'blob' })
      .then((response) => {
        // Create an object URL from the image blob
        const imageAddressUrl = URL.createObjectURL(response.data);

        setQrAddressUrl(imageAddressUrl);
      })
      .catch((error) => {
        console.error('Error generating QR code without Amount', error);
        return;
      });
    let QRWithAmount = await `${fromNetworkName}:${payInAddress}?amount=${fromAmount}`;
    const apiWithAmountsUri = `https://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(
      QRWithAmount
    )}&size=150x150`;
    // Fetch the QR code image with Amount
    await axios
      .get(apiWithAmountsUri, { responseType: 'blob' })
      .then((response) => {
        // Create an object URL from the image blob
        const imageWithAmountUrl = URL.createObjectURL(response.data);

        setQrWithAmountUrl(imageWithAmountUrl);
      })
      .catch((error) => {
        console.error('Error generating QR code with Amount', error);
      });

    closeExchangeModal();
    setCreateExchangeModalOpen(true);
    setRecipientAddress('');
  };

  const handleFromCurrencyClick = (item, currency) => {
    // You could also set the selected currency in state
    setAvailablePairs(false);
    setMinimalExchangeAmount(0);
    setSelectedFromCurrency(currency);
    setFromCurrency(item.ticker);
    setFromNetwork(item.network);
    setFromNetworkName(item.name);
    closeFromModal();
    closeToModal();
  };
  const handleToCurrencyClick = (item, currency) => {
    setAvailablePairs(false);
    setMinimalExchangeAmount(0);
    setSelectedToCurrency(currency);
    setToNetworkName(item.name);
    setToNetwork(item.network);
    setToCurrency(item.ticker);
    closeFromModal();
    closeToModal();
  };

  const onRecipientAddressChangeHandler = async (e) => {
    const value = e.target.value;
    setRecipientAddress(value);
  };

  function shortenAddress(address, chars = 4) {
    return `${address.slice(0, chars + 2)}...${address.slice(-chars)}`;
  }

  // Copy function
  const [copiedAddress, setCopiedAddress] = useState(false); // State to track if copy was successful
  const [copiedExchanID, setCopiedExchanID] = useState(false); // State to track if copy was successful

  const copyToClipboard = (type) => {
    if (type == 'address') {
      navigator.clipboard
        .writeText(toWalletAddress)
        .then(() => {
          setCopiedAddress(true); // Set copied state to true on success
          setTimeout(() => setCopiedAddress(false), 2000); // Reset after 2 seconds
        })
        .catch((err) => {
          console.error('Failed to copy: ', err);
        });
    } else if (type == 'exchangeID') {
      navigator.clipboard
        .writeText(exchangeID)
        .then(() => {
          setCopiedExchanID(true); // Set copied state to true on success
          setTimeout(() => setCopiedExchanID(false), 2000); // Reset after 2 seconds
        })
        .catch((err) => {
          console.error('Failed to copy: ', err);
        });
    }
  };

  // Return
  return (
    <div className="container">
      <div className="tabs">
        <div
          className={`tabs-${exchangeType === 'crypto' ? 'crypto__selected' : 'crypto'}`}
          onClick={onExchangeTypeHandler}
        >
          <span className="tabs-text">Exchange Crypto</span>
        </div>
      </div>
      {/* Success */}
      <ExchangeModal
        width={500}
        isOpen={finished}
        onClose={() => {
          setFinished(!finished);
          closeCreateExchangeModal();
          closeExchangeModal();
        }}
      >
        <div style={{ width: '100%' }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 6 }}>
            <GrStatusGood size={42} color="yellow" />
            <h2 className="text-center">Success</h2>
          </div>
          <div style={{ paddingTop: '20px' }}>
            <img src={logo} className="logo__image" alt="" />
          </div>

          <div style={{ width: '100%', display: 'flex', gap: 10, marginTop: '20px' }}>
            <span>
              From Network: <span style={{ color: 'yellow' }}>{fromNetworkName}</span>
            </span>
            <span>
              From Amount: <span style={{ color: 'yellow' }}>{fromAmount}</span>
              {fromCurrency}
            </span>
          </div>
          <div style={{ width: '100%', display: 'flex', gap: 10, marginTop: '20px' }}>
            <span>
              From Network: <span style={{ color: 'yellow' }}>{toNetwork}</span>
            </span>
            <span>
              From Amount: <span style={{ color: 'yellow' }}>{toAmount}</span> {toCurrency}
            </span>
          </div>
        </div>
      </ExchangeModal>
      {/* Failed */}
      <ExchangeModal
        width={500}
        isOpen={failed}
        onClose={() => {
          setFailed(!failed);
          closeCreateExchangeModal();
          closeExchangeModal();
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 6,
            marginTop: '20px',
          }}
        >
          <div>
            <RiEmotionUnhappyLine size={42} color="red" />
            <h2>Your exchange was failed</h2>
          </div>
        </div>
      </ExchangeModal>
      {/* From_Modal */}
      <ExchangeModal width={400} isOpen={fromModalOpen} onClose={closeFromModal}>
        <h3 className="" style={{ paddingTop: '20px', paddingBottom: '20px' }}>
          Select Asset to Swap
        </h3>
        <div className="filter-currency">
          <IoIosSearch size={24} color="gray" />
          <input
            className="filtering__input"
            id="filtering__input"
            type="text"
            style={{ fontSize: '20px', color: 'white' }}
            value={filterFromCurrencyText}
            onChange={handleFromFilterChange} // Trigger filter on input change
          />
        </div>
        <div className="all-currencies">
          <div style={{ fontSize: '12px', paddingBottom: '10px', paddingTop: '8px' }}>
            Popular Currencies
          </div>
          {fromAllNet.length !== 0 &&
            fromAllNet.map((item, index) => (
              <div
                className="currency-item"
                onClick={() => handleFromCurrencyClick(item, index)}
                key={index}
              >
                <img src={item.image} width={24} height={24} alt="" />
                <div className="currency-item__text">
                  <div>{item.network.toUpperCase()}</div>
                  <div style={{ opacity: 0.7 }}>{item.name.toUpperCase()}</div>
                </div>
              </div>
            ))}
        </div>
      </ExchangeModal>
      {/* To_Modal */}
      <ExchangeModal width={400} isOpen={toModalOpen} onClose={closeToModal}>
        <h3 className="" style={{ paddingTop: '20px', paddingBottom: '20px' }}>
          Select Asset to Receive
        </h3>
        <div className="filter-currency">
          <IoIosSearch size={24} color="gray" />
          <input
            className="filtering__input"
            id="filtering__input"
            type="text"
            style={{ fontSize: '20px', color: 'white' }}
            value={filterToCurrencyText}
            onChange={handleToFilterChange} // Trigger filter on input change
          />
        </div>
        <div className="all-currencies">
          <div style={{ fontSize: '12px', paddingBottom: '10px', paddingTop: '8px' }}>
            Popular Currencies
          </div>
          {toAllNet.length !== 0 &&
            toAllNet.map((item, index) => (
              <div
                className="currency-item"
                onClick={() => handleToCurrencyClick(item, index)}
                key={index}
              >
                <img src={item.image} width={24} height={24} alt="" />
                <div className="currency-item__text">
                  <div>{item.network.toUpperCase()}</div>
                  <div style={{ opacity: 0.7 }}>{item.name.toUpperCase()}</div>
                </div>
              </div>
            ))}
        </div>
      </ExchangeModal>
      {/* Exchange Crypto Modal M222 */}
      <ExchangeModal width={650} isOpen={isExchangeModalOpen} onClose={closeExchangeModal}>
        <div className="exchange-modal__content">
          <div style={{ display: 'flex', justifyContent: 'center', padding: '12px 2px' }}>
            <h1 style={{ paddingBottom: '20px' }}>Exchange Crypto</h1>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
            {/* Send */}
            <div>
              <div className="text-input__color">You send</div>
              <div className="ex-send__section" style={{ width: '100%' }}>
                {!fromAllNet.length == 0 && (
                  <div className="ex-send__crypto">
                    <div style={{ display: 'flex', marginLeft: '10px' }}>
                      <img src={fromAllNet[selectedFromCurrency].image} alt="" />
                      <span style={{ color: 'white', paddingLeft: '10px', fontSize: '12px' }}>
                        {fromAllNet[selectedFromCurrency].network.toUpperCase()}
                      </span>
                    </div>
                  </div>
                )}
                <div className="ex-send__input">
                  <input className="ex-send__input-css" type="number" value={fromAmount} />
                </div>
              </div>
            </div>
            {/* Get */}
            <div>
              <div className="text-input__color">You get</div>
              <div className="ex-send__section" style={{ width: '100%' }}>
                {!toAllNet.length == 0 && (
                  <div className="ex-send__crypto">
                    <div style={{ display: 'flex', marginLeft: '10px' }}>
                      <img src={toAllNet[selectedToCurrency].image} width={24} height={24} alt="" />
                      <span style={{ color: 'white', paddingLeft: '5px', fontSize: '12px' }}>
                        {toAllNet[selectedToCurrency].network.toUpperCase()}
                      </span>
                    </div>
                  </div>
                )}
                <div className="ex-send__input">
                  <input className="ex-send__input-css" type="number" value={toAmount} />
                </div>
              </div>
            </div>
          </div>
          <div className="text-input__color" style={{ paddingTop: '12px' }}>
            Recipient Wallet:{' ( '}
            <span style={{ fontSize: '14px', fontWeight: 'bold', color: 'yellow' }}>
              {toNetworkName}
            </span>
            {' )'}
          </div>
          <div
            className="recipient-wallet"
            style={{
              border: inputError !== '' ? '#483700 2px solid' : undefined,
            }}
          >
            <input
              className="recipient-wallet__input"
              type="text"
              value={recipientAddress}
              onChange={onRecipientAddressChangeHandler}
              placeholder={`Enter payout ${toNetworkName} address`}
            />
          </div>
          {recipientAddress == '' && (
            // <div style={{ fontSize: '12px', color: 'red', marginTop: '-5px !important' }}>
            <div className="input_invalid_error">
              Please insert your{' '}
              <span style={{ fontSize: '12px', color: 'yellow' }}>{toNetworkName}</span> payout
              address
            </div>
          )}
          {recipientAddress !== '' && validAddress == false && (
            // <div style={{ fontSize: '10px', color: 'red', marginTop: '-5px !important' }}>
            <div className="input_invalid_error">{inputError}</div>
          )}

          <div style={{ display: 'flex', paddingTop: '12px', paddingBottom: '1px' }}>
            <span style={{ fontSize: '12px', paddingRight: '8px' }}>Estimated rate: </span>
            <span style={{ fontSize: '12px' }}>
              {1}
              {fromCurrency} ≈ {rate > 0 ? rate : '...'} {toCurrency}
            </span>
          </div>
          {/* Exchange Test Button */}
          <button className="exchange-button" onClick={onHandleExchange}>
            Exchange
          </button>
          {isExpanded == false && refundAddress !== '' && validRefundAddress == false && (
            // <div style={{ fontSize: '10px', color: 'red', marginTop: '-5px !important' }}>
            <div style={{ marginTop: '7px', height: '35px' }} className="input_invalid_error">
              {refundError}
            </div>
          )}
          {/* Expand Toggle */}
          <div className="advanced-settings-container">
            {/* Main Content or Form */}
            <div className="main-settings">{/* Your main settings content goes here */}</div>

            {/* Advanced Settings Section with Transition */}
            <div
              className="advanced-settings"
              style={{
                height: isExpanded ? `${contentRef.current.scrollHeight + 7}px` : '0px',
                overflow: 'hidden',
                padding: '0px 10p',
                transition: 'height 0.5s ease',
              }}
            >
              {/* The hidden content */}
              <div ref={contentRef}>
                <div className="promo-code">
                  <label>Promo code</label>
                  <div style={{ padding: '0px 3px' }}>
                    <input
                      type="text"
                      onChange={handlePromoCodeChange}
                      placeholder="Enter your promo code"
                    />
                  </div>
                </div>
                <div className="refund-wallet">
                  <label>Refund Wallet : </label>
                  <span style={{ color: 'yellow' }}>{fromNetwork.toUpperCase()}</span>
                  <div style={{ padding: '0px 3px' }}>
                    <input
                      type="text"
                      onChange={handleRefundChange}
                      placeholder={`Enter your ${fromNetwork.toUpperCase()} address`}
                    />
                  </div>
                  {refundAddress !== '' && validRefundAddress == false && (
                    // <div style={{ fontSize: '10px', color: 'red', marginTop: '-5px !important' }}>
                    <div className="input_invalid_error">{refundError}</div>
                  )}

                  {/* <button className="scan-button">Scan</button> */}
                </div>
              </div>
            </div>

            {/* Toggle Button */}
            <div className="expand-button" onClick={toggleExpand}>
              <button className="toggle-button">
                {isExpanded ? 'Advanced Settings ▲' : 'Advanced Settings ▼'}
              </button>
            </div>
          </div>
        </div>
      </ExchangeModal>
      {/* Create Exchange Modal M333 */}
      <ExchangeModal
        width={700}
        isOpen={createExchangeModalOpen}
        onClose={closeCreateExchangeModal}
      >
        <div className="exchange-modal__content" style={{ paddingTop: '20px' }}>
          <div style={{}}>
            <span
              style={{
                paddingBottom: '20px',
                paddingTop: '20px',
                fontSize: 14,
                paddingLeft: '20px',
              }}
            >
              Please send the funds you would like to exchange
            </span>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <div
              style={{
                width: '100%',
                height: 'auto',
                backgroundColor: '#292929',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)', // Add shadow here
                borderRadius: '8px', // Optional: add rounded corners
                padding: '20px',
                margin: '20px',
              }}
            >
              <div>
                <div style={{ fontSize: '10px' }}>Amount</div>
                <div style={{ fontSize: '14px', fontWeight: 'bold' }}>
                  {fromAmount} {fromCurrency.toUpperCase()}
                </div>
              </div>
              <div style={{ paddingTop: '10px' }}>
                <div style={{ fontSize: '10px' }}>To this address</div>
                <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                  <div style={{ fontSize: '14px', fontWeight: 'bold' }}>{toWalletAddress}</div>
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      copyToClipboard('address');
                    }}
                  >
                    <LuCopy color={copiedAddress ? 'green' : 'gray'} />
                  </div>
                </div>
              </div>
              {/* Select a currency from  M111 */}
              <div className="toggle-button-container">
                <button
                  className={`toggle-button ${qrType === true ? 'active' : ''}`}
                  onClick={() => setQrType(true)}
                >
                  Address
                </button>
                <button
                  className={`toggle-button ${qrType === false ? 'active' : ''}`}
                  onClick={() => setQrType(false)}
                >
                  With amount
                </button>
              </div>

              <div
                style={{
                  marginTop: '10px',
                  width: '200px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {qrType ? (
                  <img src={qrAddressUrl} alt="QR Code" />
                ) : (
                  <img src={qrWithAmountUrl} alt="QR Code" />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="progress-indicator">
          <div className="awaiting__step">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {statusFromExchangeID === 'waiting' ? (
                <div className="icon spinner"></div>
              ) : (
                <div className="icon circle"></div>
              )}
            </div>
            <div
              style={{
                fontSize: '12px',
                color: statusFromExchangeID === 'waiting' ? 'green' : 'white',
              }}
            >
              Awaiting deposit
            </div>
            {statusFromExchangeID === 'confirming' ? (
              <div className="awaiting__step__line__active"></div>
            ) : (
              <div className="awaiting__step__line"></div>
            )}
          </div>
          <div className="awaiting__step">
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              {statusFromExchangeID === 'confirming' ? (
                <div className="icon spinner"></div>
              ) : (
                <div className="icon circle"></div>
              )}
            </div>
            <div
              style={{
                fontSize: '12px',
                color: statusFromExchangeID === 'confirming' ? 'green' : 'white',
              }}
            >
              Confirming
            </div>
            {statusFromExchangeID === 'exchanging' ? (
              <div className="awaiting__step__line__active"></div>
            ) : (
              <div className="awaiting__step__line"></div>
            )}
          </div>
          <div className="awaiting__step">
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              {statusFromExchangeID === 'exchanging' ? (
                <div className="icon spinner"></div>
              ) : (
                <div className="icon circle"></div>
              )}
            </div>
            <div
              style={{
                fontSize: '12px',
                color: statusFromExchangeID === 'exchanging' ? 'green' : 'white',
              }}
            >
              Exchanging
            </div>
            {statusFromExchangeID === 'sending' ? (
              <div className="awaiting__step__line__active"></div>
            ) : (
              <div className="awaiting__step__line"></div>
            )}
          </div>
          <div className="awaiting__step">
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              {statusFromExchangeID === 'sending' ? (
                <div className="icon spinner"></div>
              ) : (
                <div className="icon circle"></div>
              )}
            </div>
            <div
              style={{
                fontSize: '12px',
                color: statusFromExchangeID === 'sending' ? 'green' : 'white',
              }}
            >
              Sending to you
            </div>
          </div>
        </div>
        <div style={{ width: '100%' }}>
          <div>
            <div style={{ fontSize: '12px' }}>You Get</div>
            <div style={{ fontSize: '12px' }}>
              {toAmount} {toCurrency.toUpperCase()}
            </div>
          </div>
          <div>
            <div style={{ fontSize: '12px' }}>Recipient Wallet</div>
            <div style={{ fontSize: '12px' }}>{recipientAddress}</div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            paddingTop: '22px',
          }}
        >
          <div style={{ fontSize: '10px', display: 'flex', gap: 3 }}>
            You will get result to Wallet: {recipientAddress}
            {/* <div className="">{shortenAddress(recipientAddress)}</div> */}
          </div>

          <div style={{ fontSize: '10px', display: 'flex', alignItems: 'center', gap: 5 }}>
            Exchange ID
            <div className="exchange__id">{exchangeID}</div>
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                copyToClipboard('exchangeID');
              }}
            >
              <LuCopy color={copiedExchanID ? 'green' : 'gray'} />
            </div>
          </div>
        </div>
      </ExchangeModal>

      {/* Exchange */}
      {/* @@@@@ Main_Content */}
      <div className="content">
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '-5px',
            marginBottom: '10px',
          }}
        >
          {/* <div style={{ fontSize: '12px', color: 'gray', cursor: 'pointer', fontWeight: 'bold' }}>
              check tx status
            </div> */}
          <img src={logo} className="logo__image" alt="" />
        </div>
        {/* @@@@ Send */}
        <div className="text-input__color">You send</div>
        <div
          className="ex-send__section"
          style={{
            border: inputError !== '' ? '#483700 2px solid' : undefined,
          }}
        >
          {!fromAllNet.length == 0 && (
            <div
              className="ex-send__crypto"
              onClick={() => {
                openFromModalHnadler('from');
              }}
            >
              <div style={{ display: 'flex', marginLeft: '10px' }}>
                <img src={fromAllNet[selectedFromCurrency].image} width={24} height={24} alt="" />
                <span style={{ color: 'white', paddingLeft: '5px', fontSize: '12px' }}>
                  {fromAllNet[selectedFromCurrency].network.toUpperCase()}
                </span>
              </div>
            </div>
          )}
          <div className="ex-send__input">
            <input
              className="ex-send__input-css"
              type="number"
              value={fromAmount}
              placeholder="0.01"
              onChange={handleFromAmountChange}
            />
          </div>
        </div>
        {inputError !== '' && <div className="input_invalid_error">{inputError}</div>}

        <div className="exchange-icon" onClick={onExchangeIconHandler}>
          <RiExchangeDollarLine size={30} color="#f8f84f" />
          {/* <FaExchangeAlt size={20} /> */}
        </div>
        {/* @@@@ Get */}
        <div className="text-input__color">You get</div>
        <div className="ex-send__section">
          {!toAllNet.length == 0 && (
            <div
              className="ex-send__crypto"
              onClick={() => {
                openToModalHnadler('to');
              }}
            >
              <div style={{ display: 'flex', marginLeft: '10px' }}>
                <img src={toAllNet[selectedToCurrency].image} width={24} height={24} alt="" />
                <span style={{ color: 'white', paddingLeft: '5px', fontSize: '12px' }}>
                  {toAllNet[selectedToCurrency].network.toUpperCase()}
                </span>
              </div>
            </div>
          )}
          <div className="ex-send__input">
            <input
              className="ex-send__input-css"
              type="number"
              value={toAmount}
              placeholder="0.01"
              onChange={handleToAmountChange}
            />
          </div>
        </div>
        {receiveInputError !== '' && <div className="input_invalid_error">{receiveInputError}</div>}

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span style={{ fontSize: '12px' }}>Estimated rate: </span>
          <span style={{ fontSize: '12px' }}>
            1 {fromCurrency} ≈ {rate > 0 ? rate.toFixed(12) : '...'} {toCurrency}
          </span>
        </div>
        {/* Exchange Button */}
        <button className="exchange-button" onClick={handleExchange}>
          Exchange
        </button>
      </div>
    </div>
  );
};

export default ExchangeSystem;
