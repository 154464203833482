const Pairs = {
  GRO_NATIVE: {
    1: '0x5e1ddce17f355994b9af9ab7945c2503f676b807', // GRO/ETH
    56: '0xdc612572219e50f5759A441BC9E6F14C92116757',
    250: '0x2046b06eccea38a82cff8e27a61deefd84865324',
    369: '0x352c69bb115abbcee27d368eb661bf1cda0a1e07',
    43114: '0xB7a4Ca0c9B58a33B244C44a8Bf9833b0E7918429',
  },
  xxHMINE_NATIVE: {
    56: '0x44a904d884368FaF4ea1e3FD086977B769048d1C',
    250: '',
    43114: '',
  },
  xPERPS_NATIVE: {
    56: '0x187caF528650fc6aB089ec9dc9DD6F08694b0eEf',
    250: '',
    43114: '',
  },
  NATIVE_USD: {
    1: '0x0d4a11d5EEaaC28EC3F61d100daF4d40471f1852',
    56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    250: '0x2b4c76d0dc16be1c31d4c1dc53bf9b45987fc75c',
    369: '0x6753560538eca67617a9ce605178f788be7e524e',
    43114: '0xed8cbd9f0ce3c6986b22002f03c6475ceb7a6256',
  },
};

export default Pairs;
