import React from 'react';
import Footer from '../../components/NewFooter';
import useEagerConnect from '../../hooks/useEagerConnect';
import { GrowthdefiMainUiHarvestApp } from './Harvest';
import NewHeader from '@groot/components/NewHeader';

export default function Staking() {
  useEagerConnect();

  return (
    <div>
      <NewHeader />
      <GrowthdefiMainUiHarvestApp />
      <Footer />
    </div>
  );
}
