import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import './Modal.css'; // For styling

const ExchangeModal = ({ isOpen, width, onClose, children }) => {
  const [closing, setClosing] = useState(false);

  // Reset the `closing` state whenever `isOpen` changes to `true`
  useEffect(() => {
    if (isOpen) setClosing(false);
  }, [isOpen]);

  const handleClose = () => {
    setClosing(true); // Trigger fade-out effect
    setTimeout(() => {
      onClose(); // Actually close the modal after the transition
    }, 500); // 0.5 seconds delay to match the CSS transition
  };
  return (
    <div className={`modal-overlay ${isOpen ? '' : 'hidden'}`}>
      <div style={{ width }} className="modal-content">
        <button className="modal-close" onClick={handleClose} style={{ color: 'white' }}>
          &times;
        </button>
        {children}
      </div>
    </div>
  );
};

ExchangeModal.propTypes = {
  width: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export default ExchangeModal;
